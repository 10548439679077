<template>
    <div class="Cognitivecombs">
        <div class="Cogn_contents">
            <div class="Cogn_content_divs">
                    <div class="cogn_div_two">
                        <div class="Goodat">
                            <span>团队潜在机会</span>
                        </div>
                        <div class="cogn_three">
                            <ul class="cogn_three_ul">
                                <li class="cogn_three_ul_li" v-for="(item,index) in p5form" :key="index">
                                    <div class="cogn_three_div clearfix">   
                                        <span class="fl">热度为{{item.level}}颗星：</span>
                                        <span class="fl">
                                            <el-rate
                                                v-model="item.level"
                                                disabled
                                                :colors="['#ff9900','#ff9900','#ff9900']">
                                            </el-rate>
                                        </span>
                                        <!-- <i class="el-icon-star-on" v-for="(citem,cindex) in item.level" :key="cindex"></i> -->
                                    </div>
                                    <ul class="icon_ul clearfix">
                                        <li class="cogn_ul_li_item fl" v-for="(items3,indesx3) in item.values" :key="indesx3">
                                            {{items3.name}}
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="cogn_tabs">
                        <div class="Goodat">
                            <span>个人潜在机会</span>
                        </div>
                        <div class="cogn_tabs_two">
                        <el-tabs type="border-card">
                            <el-tab-pane :label="item.user" v-for="(item,index) in sortList" :key="index">
                                <ul class="cogn_three_ul">
                                    <li class="cogn_three_ul_li" v-for="(item3,index3) in item.values" :key="index3">
                                        <div class="cogn_three_div show_t_div clearfix">   
                                            <span class="fl">热度为{{item3.level}}颗星的：</span>
                                            <span class="fl">
                                                <el-rate
                                                    v-model="item3.level"
                                                    disabled
                                                    :colors="['#ff9900','#ff9900','#ff9900']">
                                                </el-rate>
                                            </span>
                                            <!-- <i class="el-icon-star-on" v-for="(citem,cindex) in item3.level" :key="cindex"></i> -->
                                        </div>
                                        <ul class="icon_ul clearfix">
                                            <li class="cogn_ul_li_item fl" v-for="(items3,indesx3) in item3.values" :key="indesx3">
                                                {{items3.name}}
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </el-tab-pane>
                        </el-tabs>   
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form:{},
            p5form:[],
            sortList:[]
        }
    },
    methods: {
        getapp(){
            this.calId=this.$route.query.calId
            this.axios.projects1({
                token:this.$store.state.token,
                id:this.calId,
                tId:this.$store.state.tem_id
            }).then(res=>{
                if(res.data.code==0){
                    this.form = res.data.data
                    this.forms = res.data.data.data
                    var str = this.form
                    this.$emit('info', str);
                    this.forms.p3.map(item=>{
                        item.values.sort((a,b)=>{
                            return b.level - a.level; //降序
                        })
                    })
                    this.sortList = this.forms.p3
                    this.p5form = this.forms.p5
                    this.sortList.sort((a, b) => {
                        //排序基于的数据
                        return b.level - a.level; //降序
                    });
                }else{
                    this.$message.error(res.data.message)
                }
            }).catch(err=>{

            })
        }
    },
    mounted() {
        this.getapp()
    }
}
</script>
<style scoped>
@import './../../../assets/css/teacher/Copypaper/CognitiveRZSL.css';
</style>
<style>
.cogn_three_div .el-rate__item{
    vertical-align: top;
}
.show_t_div .el-rate__item:nth-child(4),.show_t_div .el-rate__item:nth-child(5){
    display: none;
}
.cogn_tabs .el-tabs--border-card{
    background: transparent;
    height: 100%;
}
/* .cogn_tabs .el-tabs--border-card>.el-tabs__header{
    border: none;
    background: none;
} */
.cogn_tabs .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active{
    background: #54A9D8;
    color: #fff;
}
.cogn_tabs .el-tabs--border-card>.el-tabs__header .el-tabs__item{
    color: #333;
}
.cogn_tabs .el-tabs__item.is-top:hover{
    color: #333;
}
.cogn_tabs .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active:hover{
    color: #fff;
}
</style>